import SurveyActionTypes from "./survey.types";

const INITIAL_STATE = {
    status: 'initial',
    allSlackSurveys: [],
    singleSlackSurvey: {},
    singleSlackQuestion: {},
    templates: [],
    singleTemplate: null,
    jsonBlockStatus: ''
};


const slackSurveysReducer = (state = INITIAL_STATE, action) => {
    console.log("PL", action);
    switch (action.type) {
        case SurveyActionTypes.CHANGE_STATUS_SLACK:
            return { ...state, status: action.payload };
        case SurveyActionTypes.ADD_NEW_SURVEY_SLACK:
            return {...state, singleSlackSurvey: action.payload};
        case SurveyActionTypes.ADD_NEW_QUESTION_SLACK:
            return {...state, singleSlackQuestion: action.payload};
        case SurveyActionTypes.ADD_NEW_ANSWER_SLACK:
            return {...state};
        case SurveyActionTypes.GET_SINGLE_SURVEY_WITH_QUESTIONS_AND_ANSWERS_SLACK:
            return {...state, singleSlackSurvey: action.payload};
        case SurveyActionTypes.ADD_JSON_CARD_TO_SURVEY_SLACK:
            return {...state};
        case SurveyActionTypes.GET_ALL_SURVEYS_SLACK:
            return {...state, allSlackSurveys: action.payload};

        case SurveyActionTypes.ADD_SCHEDULE_SLACK:
            return {...state};

        case SurveyActionTypes.GET_ALL_SURVEYS_WITH_SCHEDULE_SLACK:
            return {...state, allSlackSurveys: action.payload};
        case SurveyActionTypes.DELETE_SCHEDULE_SLACK:
            return {...state};

        case SurveyActionTypes.GET_ALL_TEMPLATES_SLACK:
            return {...state, templates: action.payload}
        case SurveyActionTypes.GET_SINGLE_TEMPLATE_SLACK:
            return {...state, singleTemplate: action.payload}
        case SurveyActionTypes.UPDATED_JSON_BLOCK:
            return {...state, jsonBlockStatus: action.payload}

        default:
            return state;
    }
};

export default slackSurveysReducer;
