import { Context } from "chartjs-plugin-datalabels";
import { Paper } from "@mui/material";
import { experimentalStyled as styled } from "@mui/material/styles";
import { Pie } from "react-chartjs-2";
import { Type1Question } from "../../models/Question";
import { scoreInterpolationColor } from "../../utils/scoreColor";

const Item = styled(Paper)(({ theme }) => ({
  ...theme.typography.body2,
  padding: theme.spacing(1),
  marginBottom: theme.spacing(3),
  color: theme.palette.text.primary,
  width: "100%",
  height: "",
}));

interface Props {
  index: number;
  question: Type1Question;
}

export function PieChart({ index, question }: Props): JSX.Element {
  const sortedChoices = question.choices
    .reduce(
      (
        sortedChoices: [string, number][],
        currentValue: string,
        currentIndex: number
      ) => {
        sortedChoices.push([currentValue, question.choiceValues[currentIndex]]);
        return sortedChoices;
      },
      [] as [string, number][]
    )
    .sort(([, a], [, b]) => b - a);

  const colors = question.choices.map((value, index) =>
    scoreInterpolationColor(index, question.maxScore)
  );

  const data = {
    labels: sortedChoices.map((value) => value[0]), // question.choices,
    datasets: [
      {
        label: "label",
        data: sortedChoices.map((value) => value[1]), // question.choiceValues,
        borderWidth: 1,
        backgroundColor: colors,
      },
    ],
  };

  const options = {
    plugins: {
      datalabels: {
        color: "#111",
        textAlign: "center",
        font: {
          lineHeight: 1.6,
        },
        formatter: (value: number, ctx: Context) => {
          if (ctx.chart.data.labels && value > 0) {
            return (
              (
                (value /
                  question.choiceValues.reduce(
                    (previousValue, currentValue) =>
                      previousValue + currentValue
                  )) *
                100
              ).toFixed(2) + "%"
            );
          } else if (value === 0) {
            return "";
          }
        },
      },
    },
      responsive: true, // Habilita la capacidad de respuesta
      maintainAspectRatio: false // Permite ajustar el gráfico al contenedor
  };

  return (
    <Item style={{ height: "100%" }}>
      {/*<h3
        style={{
          fontSize: "18px",
          fontWeight: "medium",
          color: "#1A237E",
          fontFamily: "'IBM Plex Sans', sans-serif",
        }}
      >
        {index}. {question.title}
      </h3>*/}
      <div>
        <Pie
          // @ts-ignore
          options={options}
          data={data}
        />
      </div>
    </Item>
  );
}
