import React, { createContext, useEffect, useState } from "react";
import { Survey } from "../models/Survey";
import { Question } from "../models/Question";
import { QuestionService } from "../services/QuestionService";
import { SurveyService } from "../services/SurveyService";
import { Dimension } from "../models/Dimension";
import { useParams } from "react-router-dom";

interface URLParams {
  id: string;
}

interface Context {
  isLoading: boolean;
  errorMessage: string;
  survey: Survey;
  questions: Question[];
}

const ChartsContext = createContext<Context>({} as Context);

interface ChartsProviderProps {
  children: React.ReactNode;
}

function ChartsProvider(props: ChartsProviderProps): JSX.Element {
  let { id } = useParams<URLParams | any>();
  const [isLoading, setIsLoading] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [survey, setSurvey] = useState<Survey>({
    dimensions: [] as Dimension[],
    numberOfResponses: -1,
  } as Survey);
  const [questions, setQuestions] = useState<Question[]>([] as Question[]);

  useEffect(() => {
    if (id) {
      setIsLoading(true);
      (async () => {
        try {
          let survey = {} as Survey;
          let questions = [] as Question[];
          survey = await SurveyService.getSurvey(id);
          console.log("SURVEY: ", survey)
          questions = await QuestionService.getAllQuestionsOfSurvey(id);
          setSurvey(survey);
          console.log("MY QUESTIONS", questions)
          setQuestions(questions);
        } catch (err: any) {
          console.log(err);
          setErrorMessage(err.message);
        }
        setIsLoading(false);
      })();
    }
  }, []);

  return (
    <ChartsContext.Provider
      value={{
        isLoading,
        errorMessage,
        survey,
        questions,
      }}
    >
      {props.children}
    </ChartsContext.Provider>
  );
}

export { ChartsContext, ChartsProvider };

// import {connect} from "react-redux";
// import { addUserThunk, getUserThunk } from '../../../redux/users/users.thunk';

/*
const mapStateToProps = (state : any) => {
    console.log('state app:', state);
    return {
        user: state.users.user,
        status: state.users.status
    };
};

const mapDispatchToProps = (dispatch : any) => {
    return {
        getUser: (id:any) => dispatch(getUserThunk(id)),
        addUser: (obj:any) => dispatch(addUserThunk(obj))
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(SurveyDashboard);
*/
