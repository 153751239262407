import { Context } from "chartjs-plugin-datalabels";
import { Paper } from "@mui/material";
import { experimentalStyled as styled } from "@mui/material/styles";
import { Bar, Line } from "react-chartjs-2";
import { Type1Question } from "../../models/Question";
import { scoreInterpolationColor } from "../../utils/scoreColor";
import { format } from 'date-fns';
import { Item } from "../ui";
import {generateBarCharts} from '../../../components/survey/survey-create/helpers/chart';

format(new Date(2014, 1, 11), "yyyy-MM-dd");
//=> '2014-02-11'

interface Props {
  index: number;
  question: Type1Question;
  responses: any[];
}

export function BarChart({ index, question, responses }: Props): JSX.Element {
  /*
  const sortedChoices = question.choices
    .reduce(
      (
        sortedChoices: [string, number][],
        currentValue: string,
        currentIndex: number
      ) => {
        sortedChoices.push([currentValue, question.choiceValues[currentIndex]]);
        return sortedChoices;
      },
      [] as [string, number][]
    )
    .sort(([, a], [, b]) => b - a);
  */

  const colors = question.choices.map((value, index) =>
    scoreInterpolationColor(index, question.maxScore)
  );

  console.log("questions BARCHAR", responses, question)

  let data = {
    labels: question.answersData.map(answerData => format(new Date(answerData.createdAt), 'yyyy-MM-dd')), // sortedChoices.map((value) => value[0]), // question.choices,
    datasets: [
      {
        label: "label",
        data: question.answersData.map(answerData => answerData.value), // sortedChoices.map((value) => value[1]), // question.choiceValues,
        borderWidth: 1,
        backgroundColor: colors,
      },
    ],
  };
  const data2 = generateBarCharts(responses);
  const currentData: any = data2.find((d) => d.questionId == question.id.toString());
  data.labels = currentData.data.labels;
  data.datasets = currentData.data.datasets;
  console.log("data BARCHART", data2, data)

  const options = {
    plugins: {
      datalabels: {
        color: "#111",
        textAlign: "center",
        font: {
          lineHeight: 1.6,
        },
        /*
        formatter: (value: number, ctx: Context) => {
          if (ctx.chart.data.labels && value > 0) {
            return (
              (
                (value /
                  question.choiceValues.reduce(
                    (previousValue, currentValue) =>
                      previousValue + currentValue
                  )) *
                100
              ).toFixed(2) + "%"
            );
          } else if (value === 0) {
            return "";
          }
        },
        */
      },
    },
    responsive: true,
    maintainAspectRatio: false
  };

  return (
    <Item style={{ height: "100%" }}>
      {/*<h3
        style={{
          fontSize: "18px",
          fontWeight: "medium",
          color: "#1A237E",
          fontFamily: "'IBM Plex Sans', sans-serif",
        }}
      >
        {index}. {question.title}
      </h3>*/}
      <div>
        <Bar
          // @ts-ignore
          options={options}
          data={data}
        />
      </div>
    </Item>
  );
}
