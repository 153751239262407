import {
    Box,
    CircularProgress,
    FormControl,
    InputLabel,
    MenuItem,
    Select,
    ToggleButton,
    ToggleButtonGroup, Typography, useTheme
} from "@mui/material";
import {GridOn, ViewList} from "@mui/icons-material";
import React, {useEffect, useState} from "react";
import StartTemplateCard from "./start-template-card";
import {getAllTemplatesSlackThunk} from "../../../../redux/slack/survey/survey.thunk";
import {connect} from "react-redux";
import StartTemplateList from "./start-template-list";
import { useTranslation } from "react-i18next";
import {MutatingDots} from "react-loader-spinner";
import {palette} from "../../../../assets/styles/theme";
//import { getAllTemplatesSlack } from "../../../../redux/slack/survey/survey.actions";


const StartTemplateIndex = (props) => {
    const {t} = useTranslation();
    const [alignment, setAlignment] = useState('grid');

    const theme = useTheme();

    const handleAlignment = (event, newAlignment) => {
        setAlignment(newAlignment);
    };

    useEffect(() => {
        console.log("calling templates1")
        props.getAllTemplatesSlack();
    }, []);


    return (
        <div>
            <div className="row">
                <div className="col-lg-6 offset-lg-3 col-md-8 offset-md-2 text-center">
                    <Typography variant="h5" sx={{color: theme.palette.primary.main}}>
                        <b>{t("surveys.exploreTemplates")}</b>
                    </Typography>
                    <small className="my-3 text-black-50">
                        {t('surveys.templ.desc')}
                    </small>
                </div>
                {/*<div className="col-md-6 text-md-end">
                    <Paper component="form"
                           sx={{p: '2px 4px', display: 'flex', alignItems: 'center', width: 'auto'}}>
                        <IconButton type="submit" sx={{p: '10px'}} aria-label="search">
                            <Search/>
                        </IconButton>
                        <InputBase sx={{ml: 1, flex: 1}}
                                   placeholder="Search templates"
                                   inputProps={{'aria-label': 'search templates'}}/>
                    </Paper>
                </div>*/}

            </div>

            <div className="my-3">
                <div className="row">
                    <div className="col-md-6">
                        {/*<Box>
                            <FormControl fullWidth>
                                <InputLabel id="demo-simple-select-label">Filter</InputLabel>
                                <Select
                                    labelId="demo-simple-select-label"
                                    id="demo-simple-select"
                                    value="20"
                                    label="Filter">
                                    <MenuItem value={10}>Most Popular</MenuItem>
                                    <MenuItem value={20}>View all templates</MenuItem>d
                                </Select>
                            </FormControl>
                        </Box>*/}
                    </div>

                    <div className="col-md-6 text-md-end my-3 my-md-0">
                        <ToggleButtonGroup
                            value={alignment}
                            exclusive
                            onChange={handleAlignment}
                            aria-label="text alignment">
                            <ToggleButton value="grid" aria-label="Grid" sx={{borderRadius: 'auto'}}>
                                <GridOn/>
                            </ToggleButton>
                            <ToggleButton value="list" aria-label="List">
                                <ViewList/>
                            </ToggleButton>
                        </ToggleButtonGroup>
                    </div>
                </div>
            </div>
            <div>
                <div className="d-flex justify-content-center">
                    <MutatingDots
                        color={palette.primary}
                        height="100"
                        width="100"
                        visible={props.status === 'loading'}/>
                </div>
                {props.status === 'loading' ? <p className="text-center">{t('status.loadingTemplates')}... </p> : null}
            </div>
            {props.status === 'loading' ?
                null
                : alignment === 'grid' ?
                    <StartTemplateCard templates={props.templates}/>
                    : <StartTemplateList templates={props.templates}/>}
        </div>
    )
}

// Map state to props;
const mapStateToProps = (state) => {
    console.log("state", state);
    return {
        templates: state.slack.templates,
        status: state.slack.status
    };
};

// Map dispatch to props;
const mapDispatchToProps = (dispatch) => {
    return {
        getAllTemplatesSlack: () => dispatch(getAllTemplatesSlackThunk()),

    };
};

export default connect(mapStateToProps, mapDispatchToProps)(StartTemplateIndex);