import { Box, Unstable_Grid2 as Grid } from "@mui/material";
import { Question, Type1Question, Type2Question } from "../../models/Question";
import { Type1QuestionChart } from "./Type1QuestionChart";
import { Type2QuestionChart } from "./Type2QuestionChart";
import { BarChart } from "./BarChart";
import { PieChart } from "./PieChart";

interface Props {
  questions: Question[];
  type: "type1" | "type2";
  startIndex?: number;
  responses: any[]
}

export function ChartsBlock2({
    responses,
  questions,
  type,
  startIndex = 1,
}: Props): JSX.Element {
  if (type === "type1") {
    return (
      <>
        {questions.map((question, index) => (
          <>
            <h3
                style={{
                  fontSize: "18px",
                  fontWeight: "medium",
                  color: "#1A237E",
                  fontFamily: "'IBM Plex Sans', sans-serif",
                }}
            >
              {index + 1}. {question.title}
            </h3>
            <Box key={question.id} className="col-md-6 my-2">
              {
                <BarChart
                    responses={responses}
                  question={question as Type1Question}
                  index={startIndex + index}
                />
              }
            </Box>
            <Box key={question.id} className="col-md-6 my-2">
              {
                <PieChart
                  question={question as Type1Question}
                  index={startIndex + index}
                />
              }
            </Box>
          </>
        ))}
      </>
    );
  }

  /*
  if (type === "type2") {
    return (
      <Box>
        {questions.map((question, index) => (
          <Box key={question.id}>
            {
              <Type2QuestionChart
                question={question as Type2Question}
                index={startIndex + index}
              />
            }
          </Box>
        ))}
      </Box>
    );
  }
  */

  return <></>;
}
