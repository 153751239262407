import axios from "axios";

export const addNewSurveySlackService = async (obj) => {
    const { data } = await axios.post(`${process.env.REACT_APP_BACKEND_URL}/api/slack/surveys/newSurvey`, obj);
    return data;
}

export const addNewQuestionSlackService = async (obj) => {
    const { data } = await axios.post(`${process.env.REACT_APP_BACKEND_URL}/api/slack/questions/newQuestion`, obj);
    return data;
}

export const addNewAnswerSlackService = async (obj) => {
    const { data } = await axios.post(`${process.env.REACT_APP_BACKEND_URL}/api/slack/answers/newAnswer`, obj);
    return data;
}

export const getSingleSurveysWithQandASlackService = async (id) => {
    const { data } = await axios.get(`${process.env.REACT_APP_BACKEND_URL}/api/slack/surveys/singleSurveyFull/${id}`);
    return data;
}

export const addJSONCardtoSurveySlackService = async (id, obj) => {
    const { data } = await axios.put(`${process.env.REACT_APP_BACKEND_URL}/api/slack/surveys/newSurvey/json/${id}`, obj);
    return data;
}

export const getAllSurveysSlackService = async () => {
    const { data } = await axios.get(`${process.env.REACT_APP_BACKEND_URL}/api/slack/surveys/allSurveys`);
    return data;
}


export const addJSONtoSurveySlackService = async (id, obj) => {
    const { data } = await axios.put(`${process.env.REACT_APP_BACKEND_URL}/api/slack/surveys/newSurvey/${id}`, obj);
    return data;
}

export const addScheduleSlackService = async (obj) => {
    const { data } = await axios.post(`${process.env.REACT_APP_BACKEND_URL}/api/slack/schedules/newSchedule`, obj);
    return data;
}

export const getAllSurveysWithScheduleSlackService = async (teamId) => {
    const { data } = await axios.get(`${process.env.REACT_APP_BACKEND_URL}/api/slack/surveys/allSurveysWithSchedule/${teamId}`);
    return data;
}

export const deleteScheduleSlackService = async (id) => {
    const { data } = await axios.put(`${process.env.REACT_APP_BACKEND_URL}/api/slack/schedules/editSchedule/${id}`, {
        state: false
    });
    return data;
}


export const addNewFullSurveySlackService = async (obj) => {
    console.log("FULL SURVEY", obj)
    const { data } = await axios.post(`${process.env.REACT_APP_BACKEND_URL}/api/slack/surveys/newFullSurvey`, obj);
    return data;
}

export const getAllSurveysForUserSlackService = async (userId) =>{
    const { data } = await axios.get(
        `${process.env.REACT_APP_BACKEND_URL}/api/slack/surveys/allUserSurveys/${userId}`
      );
     return data;
}

export const getSingleSurveySlackService = async (id) => {
    const { data } = await axios.get(`${process.env.REACT_APP_BACKEND_URL}/api/slack/surveys/singleSurvey/${id}`);
    return data;
}

export const addNewResponseSlackService = async (obj) => {
    const { data } = await axios.post(`${process.env.REACT_APP_BACKEND_URL}/api/slack/responses/newResponse`, obj);
    return data;
}

export const getAllQuestionsBySurveyIdSlackService = async (id) => {
    const { data } = await axios.get(`${process.env.REACT_APP_BACKEND_URL}/api/slack/questions/allQuestions/${id}`);
    return data;
}

export const deleteSurveySlackService = async (id) => {
    const { data } = await axios.delete(`${process.env.REACT_APP_BACKEND_URL}/api/slack/surveys/deleteSurvey/${id}`);
    return data;
}

export const getAllTemplatesSlackService = async () => {
    console.log("Calling templates2")
    const { data } = await axios.get(`${process.env.REACT_APP_BACKEND_URL}/api/slack/surveys/templates`);
    return data;
}

export const getSingleTemplateSlackService = async (id) => {
    const { data } = await axios.get(`${process.env.REACT_APP_BACKEND_URL}/api/slack/surveys/templates/${id}`);
    return data;
}

