import imgEmployee from '../../../assets/templates/employe engagement.png';
import imgEnps from '../../../assets/templates/eNPS.png';
import imgEmploySatisfaction from '../../../assets/templates/Employee Satisfaction Survey.png';
import imgAssessment from '../../../assets/templates/Assessment of the incentive system and benefits.png';
import imgRelationship from '../../../assets/templates/Relationship with Manager.png';
import imgStress from '../../../assets/templates/Stress at work.png';
import {
    addNewResponseSlack,
    deleteSurveySlack,
    getAllQuestionsBySurveyIdSlack,
    getAllTemplatesSlack,
    getSingleSurveySlack,
    getSingleTemplateSlack,

    addJSONCardtoSurveySlack,

    addJSONtoSurveySlack,
    addNewAnswerSlack,
    addNewQuestionSlack,
    addNewSurveySlack,
    addScheduleSlack,
    changeStatusSlack,
    deleteScheduleSlack,
    getAllSurveysSlack,
    getAllSurveysWithScheduleSlack,
    getSingleSurveyWithQandASlack, updateJsonBlockSlack
} from "./survey.actions";
import {
    addJSONCardtoSurveySlackService,
    addJSONtoSurveySlackService,
    addNewAnswerSlackService,
    addNewQuestionSlackService,
    addNewSurveySlackService,
    addScheduleSlackService, deleteScheduleSlackService,
    getAllSurveysSlackService,
    getAllSurveysWithScheduleSlackService,
    getSingleSurveysWithQandASlackService,

    addNewResponseSlackService,
    deleteSurveySlackService,
    getAllQuestionsBySurveyIdSlackService,
    getSingleSurveySlackService,
    getAllSurveysForUserSlackService, getAllTemplatesSlackService, getSingleTemplateSlackService, addNewFullSurveySlackService
} from "../../../services/survey.service";

export const addNewSurveySlackThunk = (obj) => {
    return async (dispatch) => {
        try {
            const data = await addNewSurveySlackService(obj);
            console.log("data", data);
            if (!data.error) dispatch(addNewSurveySlack(data));
        } catch (error) {
            console.error(error);
        }
    };
};

export const addNewQuestionSlackThunk = (obj) => {
    return async (dispatch) => {
        try {
            const data = await addNewQuestionSlackService(obj);
            console.log("data", data);
            if (!data.error) dispatch(addNewQuestionSlack(data));
        } catch (error) {
            console.error(error);
        }
    };
};

export const addNewAnswerSlackThunk = (obj) => {
    return async (dispatch) => {
        try {
            const { data } = await addNewAnswerSlackService(obj);
            console.log("data", data);
            if (!data?.error) dispatch(addNewAnswerSlack(data));
        } catch (error) {
            console.error(error);
        }
    };
};

export const addJSONtoSurveySlackThunk = (id, obj) => {
    return async (dispatch) => {
        try {
            const data = await addJSONtoSurveySlackService(id, obj);
            console.log("data", data);
            if (!data.error) dispatch(addJSONtoSurveySlack(data));
        } catch (error) {
            console.error(error);
        }
    };
};

//adds json for mf teams card
export const addJSONCardtoSurveySlackThunk = (id, obj) => {
    return async (dispatch) => {
        try {
            const data = await addJSONCardtoSurveySlackService(id, obj);
            console.log("data", data);
            if (!data.error) {
                dispatch(addJSONCardtoSurveySlack(data));
                dispatch(updateJsonBlockSlack('success'))
            }
        } catch (error) {
            console.error(error);
        }
    };
};

export const getAllSurveysSlackThunk = () => {
    return async (dispatch) => {
        try {
            const data = await getAllSurveysSlackService();
            console.log("data", data);
            if (!data.error) dispatch(getAllSurveysSlack(data));
        } catch (error) {
            console.error(error);
        }
    };
};

export const getAllSurveysWithScheduleSlackThunk = (tenantId) => {
    return async (dispatch) => {
        try {
            const data = await getAllSurveysWithScheduleSlackService(tenantId);
            console.log("data", data);
            if (!data.error) dispatch(getAllSurveysWithScheduleSlack(data));
        } catch (error) {
            console.error(error);
        }
    };
};

export const getAllUserSurveysSlackThunk = (userId) => {
    return async (dispatch) => {
      try {
        const data = await getAllSurveysForUserSlackService(userId);
        console.log("data", data);
        if (!data.error) dispatch(getAllSurveysWithScheduleSlack(data));
      } catch (error) {
        console.error(error);
      }
    };
  };

export const getSingleSurveySlackThunk = (id) => {
    return async (dispatch) => {
        try {
            const data = await getSingleSurveySlackService(id);
            console.log("data", data);
            if (!data.error) dispatch(getSingleSurveySlack(data));
        } catch (error) {
            console.error(error);
        }
    };
};

export const addNewResponseSlackThunk = (obj) => {
    return async (dispatch) => {
        try {
            const data = await addNewResponseSlackService(obj);
            console.log("data", data);
            if (!data.error) dispatch(addNewResponseSlack(data));
        } catch (error) {
            console.error(error);
        }
    };
};

export const getAllQuestionsBySurveyIdSlackThunk = (id) => {
    return async (dispatch) => {
        try {
            const data = await getAllQuestionsBySurveyIdSlackService(id);
            console.log("data", data);
            if (!data.error) dispatch(getAllQuestionsBySurveyIdSlack(data));
        } catch (error) {
            console.error(error);
        }
    };
};

export const getSingleSurveysWithQandASlackThunk = (id) => {
    return async (dispatch) => {
        dispatch(updateJsonBlockSlack('loading'))
        try {
            const data = await getSingleSurveysWithQandASlackService(id);
            console.log("data", data);
            if (!data.error) {
                dispatch(getSingleSurveyWithQandASlack(data));
                dispatch(updateJsonBlockSlack('loaded'))
            }
        } catch (error) {
            console.error(error);
        } finally {
            dispatch(updateJsonBlockSlack('loaded'))
        }


    };
};

export const deleteSurveySlackThunk = (id) => {
    return async (dispatch) => {
        try {
            const data = await deleteSurveySlackService(id);
            console.log("data", data);
            if (!data.error) dispatch(deleteSurveySlack());
        } catch (error) {
            console.error(error);
        }
    };
};


//not deleting, but updating
export const deleteScheduleSlackThunk = (id) => {
    return async (dispatch) => {
        try {
            const data = await deleteScheduleSlackService(id);
            console.log("data", data);
            if (!data.error) dispatch(deleteScheduleSlack());
        } catch (error) {
            console.error(error);
        }
    };
};

export const addScheduleSlackThunk = (obj) => {
    return async (dispatch) => {
        try {
            const data = await addScheduleSlackService(obj);
            console.log("data", data);
            if (!data.error) dispatch(addScheduleSlack(data));
        } catch (error) {
            console.error(error);
        }
    };
};


export const getAllTemplatesSlackThunk = () => {
    return async (dispatch) => {
        // dispatch(changeStatus('loading'));
        try {
            let data = await getAllTemplatesSlackService();
            data = data.map((item, index) => {
                switch (index) {
                    case 0:
                        item.image = 'https://riskandinsurance.com/wp-content/uploads/2019/09/burnout-700x525.jpg';
                        break;
                    // case 1: item.image = 'https://www.openmet.com/wp-content/uploads/2016/02/nps-openmet.jpg';break;
                    case 1:
                        item.image = imgEnps;
                        break;
                    //case 2: item.image = 'https://s3-ap-southeast-1.amazonaws.com/s3-studilmu-ap-southeast-1.amazonaws.com/image/blogs/1539578130_tingkatkanemployeeengagement.jpg';break;
                    case 2:
                        item.image = imgEmployee;
                        break;
                    case 3:
                        item.image = imgEmploySatisfaction;
                        break;
                    case 4:
                        item.image = imgAssessment;
                        break;
                    case 5:
                        item.image = imgRelationship;
                        break;
                    case 6:
                        item.image = imgStress;
                        break;
                    default:
                        item.image = 'https://www.myhrtoolkit.com/hubfs/differences-employee-worker.jpg';
                        break;
                }
                return item;
            })
            console.log("dataTemplate", data);
            if (!data.error) {
                dispatch(getAllTemplatesSlack(data));
                // dispatch(changeStatus('loaded'));
            }
        } catch (error) {
            console.error(error);
        }
    };
};

export const getSingleTemplateSlackThunk = (id) => {
    return async (dispatch) => {
        // dispatch(changeStatus('loading'));
        try {
            const data = await getSingleTemplateSlackService(id);
            console.log("data", data);
            if (!data.error) {
                dispatch(getSingleTemplateSlack(data));
                // dispatch(changeStatus('loaded'));
            }
        } catch (error) {
            console.error(error);
        }
    };
};


export const addNewFullSurveySlackThunk = (obj) => {
    return async (dispatch) => {
        // dispatch(changeStatus('loading'));
        try {
            const data = await addNewFullSurveySlackService(obj);
            console.log("data", data);
            if (!data.error) {
                dispatch(addNewSurveySlack(data));
                // dispatch(changeStatus('loaded'));
            }
        } catch (error) {
            console.error(error);
            // dispatch(errorSurvey(error));
        }
    };
};
