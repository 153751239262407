const SurveyActionTypes = {
    ADD_NEW_SURVEY_SLACK: "ADD_NEW_SURVEY_SLACK",
    ADD_NEW_QUESTION_SLACK: "ADD_NEW_QUESTION_SLACK",
    ADD_NEW_ANSWER_SLACK: "ADD_NEW_ANSWER_SLACK",
    GET_SINGLE_SURVEY_WITH_QUESTIONS_AND_ANSWERS_SLACK:
    "GET_SINGLE_SURVEY_WITH_QUESTIONS_AND_ANSWERS_SLACK",
    ADD_JSON_CARD_TO_SURVEY_SLACK: "ADD_JSON_CARD_TO_SURVEY_SLACK",
    GET_ALL_SURVEYS_SLACK: "GET_ALL_SURVEYS_SLACK",
    ADD_JSON_TO_SURVEY_SLACK: "ADD_JSON_TO_SURVEY_SLACK",
    ADD_SCHEDULE_SLACK: "ADD_SCHEDULE_SLACK",
    GET_ALL_SURVEYS_WITH_SCHEDULE_SLACK: "GET_ALL_SURVEYS_WITH_SCHEDULE_SLACK",
    DELETE_SCHEDULE_SLACK: "DELETE_SCHEDULE_SLACK",
    CHANGE_STATUS_SLACK: "CHANGE_STATUS_SLACK",
    GET_ALL_TEMPLATES_SLACK: "GET_ALL_TEMPLATES_SLACK",
    GET_SINGLE_TEMPLATE_SLACK: "GET_SINGLE_TEMPLATE_SLACK",
    UPDATED_JSON_BLOCK: "UPDATED_JSON_BLOCK"
  };
  
  export default SurveyActionTypes;
