import { useContext } from "react";
import { ChartsContext } from "../components/ChartsContext";
import { Question } from "../models/Question";
import { ReturnBar } from "../components/ui";
import { ChartsBlock } from "../components/charts/ChartsBlock";
import { Typography, Box } from "@mui/material";
import { Type1QuestionChart } from "../components/charts/Type1QuestionChart";
import { Type2QuestionChart } from "../components/charts";
import { ChartsBlock2 } from "../components/charts/ChartsBlock2";

export default function QuestionsData(): JSX.Element {
  const chartsContext = useContext(ChartsContext);
  const { survey, questions } = chartsContext;

  let questionBlock = { questions: [] as Question[] } as {
    questions: Question[];
    type: "type1" | "type2";
  };
  const questionBlocks = [] as (typeof questionBlock)[];

  let lastQuestionType = "";
  for (const question of questions) {
    if (
      question.type !== lastQuestionType &&
      questionBlock.questions.length > 0
    ) {
      switch (lastQuestionType) {
        case "checkbox":
        case "dropdown":
        case "emotionsratings":
        case "imagepicker":
        case "matrix":
        case "radiogroup":
        case "rating":
          questionBlock.type = "type1";
          break;
        case "comment":
        case "text":
          questionBlock.type = "type2";
          break;
      }
      questionBlocks.push(questionBlock);
      questionBlock = {
        questions: [] as Question[],
      } as {
        questions: Question[];
        type: "type1" | "type2";
      };
    }
    questionBlock.questions.push(question);
    lastQuestionType = question.type;
  }
  if (questionBlock.questions.length > 0) {
    switch (lastQuestionType) {
      case "checkbox":
      case "dropdown":
      case "emotionsratings":
      case "imagepicker":
      case "matrix":
      case "radiogroup":
      case "rating":
        questionBlock.type = "type1";
        break;
      case "comment":
      case "text":
        questionBlock.type = "type2";
        break;
    }
    questionBlocks.push(questionBlock);
  }
  console.log("questionBlocks:", questionBlocks);

  /*
  const questionBlocksQuestionsLength = questionBlocks.map(
    (value) => value.questions.length
  );
  */

  let questionBlocksStartIndexes = [1];
  let sumOfQuestionsLength = 1;
  for (const questionBlock of questionBlocks) {
    questionBlocksStartIndexes.push(
      questionBlock.questions.length + sumOfQuestionsLength
    );
    sumOfQuestionsLength += questionBlock.questions.length;
  }
  console.log("questionBlocksStartIndexes:", questionBlocksStartIndexes);
  console.log("survey template:", survey.templateId)
  console.log("survey template:", survey)

  return (
    <>
      <div>
        <ReturnBar title={survey.title} />
        <h3
          style={{
            fontSize: "20px",
            fontFamily: "'IBM Plex Sans', sans-serif",
            fontWeight: "semibold",
            color: "#1A237E",
            padding: "10px",
          }}
        >
          Questions Data
        </h3>
        
        <div className="row">
        {survey.templateId !== 1
          && questionBlocks.map((questionBlock, index) => (
                <ChartsBlock
                    key={index}
                  questions={questionBlock.questions}
                  type={questionBlock.type}
                  startIndex={questionBlocksStartIndexes[index]}
                />
            ))}
        {survey.templateId === 1
            && questionBlocks.map((questionBlock, index) => (
                <ChartsBlock2
                    key={index}
                    responses={survey.slack_responses}
                  questions={questionBlock.questions}
                  type={questionBlock.type}
                  startIndex={questionBlocksStartIndexes[index]}
                />
            ))}
        </div>
      </div>
    </>
  );
}
