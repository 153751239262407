import axios from "axios";
import { Question } from "../models/Question";
import { QuestionsConverter } from "../utils/data-conversion/questionsConverter";
// import { convertToChartData } from "../utils/data-conversion/questionsConverter2";
// import { SurveyFaker } from "../utils/surveyFaker/surveyFaker";

export class QuestionService {
  private static singleSurveyFullURL: string =
    `${process.env.REACT_APP_BACKEND_URL}/api/slack/surveys/singleSurveyFull`;
  private static singleSurveyWithRURL: string =
    `${process.env.REACT_APP_BACKEND_URL}/api/slack/surveys/singleSurveyWithR`;

  public static async getAllQuestionsOfSurvey(
    surveyId: string
  ): Promise<Question[]> {
    let questions = [] as Question[];

    let singleSurveyFull: any = (
      await axios.get(this.singleSurveyFullURL + "/" + surveyId)
    ).data;
    let singleSurveyWithR: any = (
      await axios.get(this.singleSurveyWithRURL + "/" + surveyId)
    ).data;

    const questionsConverter = new QuestionsConverter(
      singleSurveyFull,
      singleSurveyWithR,
      "slack"
    );
    questionsConverter.generateQuestions();
    questions = questionsConverter.getQuestions();
    console.log("calling function:", singleSurveyWithR);
    console.log("calling function2:", singleSurveyFull);
    // const questionsTime = convertToChartData(singleSurveyWithR);

    // console.log("questions2:",questionsTime)
    // questions = SurveyFaker.generateQuestions(10);

    console.log("questions:", questions);

    return questions;
  }

  /*
  public static async getQuestion(id: string): Promise<Question> {
    return {} as Question;
  }
  */
}
