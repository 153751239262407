import { Box, Unstable_Grid2 as Grid } from "@mui/material";
import { Question, Type1Question, Type2Question } from "../../models/Question";
import { Type1QuestionChart } from "./Type1QuestionChart";
import { Type3QuestionChart } from "./Type3QuestionChart";

interface Props {
  questions: Question[];
  type: "type1" | "type2";
  startIndex?: number;
}

export function ChartsBlock({
  questions,
  type,
  startIndex = 1,
}: Props): JSX.Element {
  if (type === "type1" || type === 'type2') {
    return (
      <>
        {type === 'type1' && questions.map((question, index) => (
          <Box key={question.id} className="col-md-6 my-2">
            {
            <Type1QuestionChart
              question={question as Type1Question}
              index={startIndex + index}
            />
          }
          </Box>
        ))}

        {type === 'type2' && questions.map((question, index) => (
            <Box key={question.id} className="col-md-6 my-2">
              {
                <Type3QuestionChart
                    question={question as Type2Question}
                    index={startIndex + index}
                />
              }
            </Box>
        ))}
      </>
    );
  }

  return <></>;
}
