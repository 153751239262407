export function generateBarCharts(obj2) {
    // Extraemos las respuestas del segundo objeto
    const responses = obj2;

    // Creamos un mapa para agrupar las respuestas por pregunta
    const groupedByQuestion = {};

    responses.forEach((response) => {
        const questionId = response.slackQuestionId;
        const answer = response.content;
        const createdAt = new Date(response.createdAt).toLocaleDateString();

        if (!groupedByQuestion[questionId]) {
            groupedByQuestion[questionId] = {};
        }

        if (!groupedByQuestion[questionId][createdAt]) {
            groupedByQuestion[questionId][createdAt] = {};
        }

        if (!groupedByQuestion[questionId][createdAt][answer]) {
            groupedByQuestion[questionId][createdAt][answer] = 0;
        }

        groupedByQuestion[questionId][createdAt][answer] += 1;
    });

    // Generamos los gráficos de barras
    const charts = Object.keys(groupedByQuestion).map((questionId) => {
        const dataByDate = groupedByQuestion[questionId];

        const labels = Object.keys(dataByDate); // Fechas (X)
        const datasets = [];

        // Crear datasets dinámicamente basados en las respuestas
        const answerKeys = new Set();
        Object.values(dataByDate).forEach((answers) => {
            Object.keys(answers).forEach((key) => answerKeys.add(key));
        });

        answerKeys.forEach((answerKey) => {
            const data = labels.map((date) => dataByDate[date][answerKey] || 0);
            datasets.push({
                label: answerKey,
                data,
            });
        });

        return {
            type: 'bar',
            questionId,
            data: {
                labels, // Fechas en X
                datasets, // Datos por opción
            },
        };
    });

    return charts;
}
