import {
    Alert,
    AlertTitle,
    Button,
    ButtonGroup,
    Card,
    CardContent,
    CircularProgress,
    Collapse,
    IconButton,
    Typography
} from "@mui/material";
import {Close, ScheduleSend, Send} from "@mui/icons-material";
import React, {Component} from "react";
import SlackDateTimePicker from "../../survey-index/schedule-slack/SlackDateTimePicker";
import axios from "axios";
import querystring from "querystring";
import {getSingleSurveysWithQandASlackThunk} from "../../../../redux/slack/survey/survey.thunk";
import {connect} from "react-redux";
import DisplaySchedule from "../../survey-index/schedule-slack/DisplaySchedule";
import {Link} from "react-router-dom";

class SaveIndex extends Component {
    state = {
        sending: false,
        open: true,
        onOpen: false,
        msg: '',
        status: '',
        id: null
    }

    /*const [sending, setSending] = useState([]);
    const [open, setOpen] = useState(true);
    const [onOpen, setOnOpen] = useState(false);
    const [msg, setMsg] = useState('');
    const [status, setStatus] = useState('');*/
    constructor(props) {
        super(props);

        const path = window.location.pathname.split('/')
        const id = path[path.length - 1];

        this.setState({id});

        this.props.getSingleSurveySlack(id);

    }

    sendSurveyToTeam = async () => {
        this.setState({sending: true});

        console.log(this.props.singleSlackSurvey);

        let res = await axios.get(`${process.env.REACT_APP_BACKEND_URL}/api/slack/users/all/${this.props.singleSlackSurvey.teamId}`);
        console.log(res);
        let token = await axios.get(`${process.env.REACT_APP_BACKEND_URL}/api/slack/installations/getOne/${this.props.singleSlackSurvey.teamId}`);
        console.log("token:",token);

        token = token.data[0].bot_token;

        let users = res.data;

        let count = 0;

        for (let us of users) {
            let url = `https://slack.com/api/chat.postMessage`
            let obj = {
                token: token,
                channel: us.id,
                text: "New Survey",
                blocks: JSON.stringify(this.props.singleSlackSurvey.jsonBlocks)
            }
            try {
                //querystring because body needs to be in form-data format
                let response = await axios.post(url, querystring.stringify(obj));
                console.log(response);
                if (!response.data.error) {
                    count++;
                }
            } catch (error) {
                console.log(error);
            }
        }

        console.log(count);

        if (count > 0) {
            this.setState({
                onOpen: true,
                status: 'success',
                msg: `Succesfully sent ${this.props.singleSlackSurvey.title} to ${count} people`
            });
        } else {
            this.setState({
                onOpen: true,
                status: 'error',
                msg: `An error occured when sending, please try again `
            });
        }
        this.setState({sending: false});
    }

    getActiveSchedule = () => {
        console.log(this.props.singleSlackSurvey);
        if (this.props.singleSlackSurvey.slack_schedules && this.props.singleSlackSurvey.slack_schedules.length > 0) {
            for (let schedule of this.props.singleSlackSurvey.slack_schedules) {
                if (schedule.state === true) {
                    return schedule;
                }
            }
        }
        return false;
    }

    render() {
        const {t} = this.props;
        return (
            <div className="d-flex justify-content-center align-items-center">
                <div>
                    <div className="py-3 my-3 text-end">
                        <Link to={`/`} style={{textDecoration: 'none'}}>
                            <Button variant="contained">{t('option.back')}</Button>
                        </Link>
                    </div>
                    <Collapse in={this.state.open} className="my-3">
                        <Alert severity="success" className="w-100" action={
                            <IconButton
                                aria-label="close"
                                color="inherit"
                                size="small"
                                onClick={() => {
                                    this.setState({open: false});
                                }}>
                                <Close fontSize="inherit"/>
                            </IconButton>}>
                            <AlertTitle>{t("success")}</AlertTitle>
                            {t('save.success')}
                        </Alert>
                    </Collapse>
                    {/*<Card>
                    <CardContent>
                        <p>{this.props.t('save.message')}</p>
                        <ButtonGroup disableElevation variant="contained" className="w-100 my-3">
                            <Button color="primary"
                                className="w-100 mx-0 br-none-first"
                                startIcon={<Link sx={{ opacity: { md: 1, xs: 0 } }} />}
                                disabled>
                                {`${process.env.REACT_APP_REDIRECT_URI}/r/${id}`}
                            </Button>
                            <CopyToClipboard text={`${process.env.REACT_APP_REDIRECT_URI}/r/${id}`} onCopy={onCopyText}>
                                <Button className="px-3 mx-0 br-none-last">
                                    <ContentCopyOutlined />
                                </Button>
                            </CopyToClipboard>
                        </ButtonGroup>
                        <div className="text-center">
                            <CopyToClipboard text={`${process.env.REACT_APP_REDIRECT_URI}/r/${id}`} onCopy={onCopyText}>
                                <Button variant="contained" color="primary" size="large" className="my-2" startIcon={<Share />}>
                                    {this.props.t('save.share')}                            </Button>
                            </CopyToClipboard>
                        </div>
                    </CardContent>
                </Card>*/}
                    {this.state.sending ?
                        <div className="text-center">
                            <CircularProgress/>
                            {t('sending')}...
                        </div> :
                        <div>
                            <Card className="col-md-8 offset-md-2 my-3">

                                {this.props.jsonBlockStatus === 'loaded' || this.props.jsonBlockStatus === ''? <ButtonGroup disableElevation variant="text" className="w-100 my-3">
                                    <Button className="w-100 mx-0 br-none-first flex-wrap border-end"
                                            onClick={() => this.sendSurveyToTeam()}>
                                        <Send className="w-100 fs-1" color="primary"/>
                                        <Typography sx={{display: {xs: 'none', md: 'inline'}}}>Send to Your Team</Typography>
                                    </Button>
                                    <SlackDateTimePicker surveyId={this.props.singleSlackSurvey.id}
                                                         className="w-100 mx-0 br-none flex-wrap">
                                    <span>
                                        <ScheduleSend className="w-100 fs-1" color="secondary"/>
                                        <Typography
                                            sx={{display: {xs: 'none', md: 'inline'}}}>Schedule sending</Typography>
                                    </span>
                                    </SlackDateTimePicker>
                                </ButtonGroup> : <div className="text-center">
                                    <CircularProgress/>
                                </div> }
                            </Card>
                            {/*<Card className="col-md-8 offset-md-2 my-3">
                                    <CardContent>
                                        {this.getActiveSchedule() ?
                                        <DisplaySchedule schedule={this.getActiveSchedule()}/>
                                            : <p className="text-center"><em>You haven't scheduled a shipment yet.</em></p>}
                                    </CardContent>
                                </Card>*/}
                        </div>
                    }

                    {this.state.onOpen ?
                        <Alert severity={this.state.status} className="alert-style my-3"
                               onClose={() => this.setState({onOpen: false})}>{this.state.msg}</Alert> : null
                    }
                </div>
            </div>
        )
    }
}

// Map state to props;
const mapStateToProps = (state) => {
    console.log("state save", state);
    return {
        singleSlackSurvey: state.slack.singleSlackSurvey,
        jsonBlockStatus: state.slack.jsonBlockStatus,
        selectedTeam: state.users.selectedTeam
    };
};

// Map dispatch to props;
const mapDispatchToProps = (dispatch) => {
    return {
        getSingleSurveySlack: (id) => dispatch(getSingleSurveysWithQandASlackThunk(id))
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(SaveIndex);
