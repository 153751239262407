import { useContext } from "react";
import { useNavigate } from "react-router-dom";
import { ArrowBack as ArrowBackIcon } from "@mui/icons-material";
import { Typography, Box } from "@mui/material";
import { ChartsContext } from "../ChartsContext";
import moment from "moment";

interface Props {
  title: string;
}

export function ReturnBar({ title }: Props): JSX.Element {
  const navigate = useNavigate();
  const { survey } = useContext(ChartsContext);
  const creationDate = moment(survey.createdAt).format('ll');
  return (
    <>
      <Box
        height={"80px"}
        alignItems={"center"}
        boxShadow={"3"}
        borderRadius={"10px"}
        padding={"10px"}
      >
        <h3
          style={{fontSize:'22px', fontFamily:"'IBM Plex Sans', sans-serif", fontWeight:"semibold", color:'#1A237E'}}
        >
          <ArrowBackIcon style={{ cursor: "pointer" }} onClick={() => navigate('/surveys-slack')} /> {title}
        </h3>
        <h3
          style={{fontSize:'16px', fontFamily:"'IBM Plex Sans', sans-serif", fontWeight:"regular", color:'#1A237E'}}
        >
          {creationDate}
        </h3>
      </Box>
    </>
  );
}
